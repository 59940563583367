<template>
  <div class="main-view">

    <v-btn color="primary" class="float-right" :to="{name: 'textsnippet-add'}">Anlegen</v-btn>
    <h1 class="headline">Textsnippets bearbeiten</h1>

    <div
      v-for="textSnippetEntry in textSnippets"
      :key="textSnippetEntry.id"
      class="mt-5"
    >
      <h1>{{ textSnippetEntry.name }}</h1>
      <div>Erstellt: {{ textSnippetEntry.createdAt|i18n_datetimeToGerman }}<span v-if="textSnippetEntry.createdBy">, {{ textSnippetEntry.createdBy.firstName }} {{ textSnippetEntry.createdBy.lastName }}</span></div>
      <div>Zuletzt bearbeitet: {{ textSnippetEntry.modifiedAt|i18n_datetimeToGerman }}<span v-if="textSnippetEntry.modifiedBy">, {{ textSnippetEntry.modifiedBy.firstName }} {{ textSnippetEntry.modifiedBy.lastName }}</span></div>
      <v-btn
        color="primary"
        :to="{name: 'textsnippet-edit', params: {textSnippetId: textSnippetEntry.id}}"
      >
        Bearbeiten
      </v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        @click="deleteTextSnippet(textSnippetEntry)"
        :loading="deleteTextSnippetId[textSnippetEntry.id]"
      >
        Löschen
      </v-btn>

      <v-simple-table class="entryRestrictions" style="clear: both">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left snippet-text">Text (de)</th>
              <th class="text-left snippet-text">Text (en)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>           
              <tr>
                <td>{{ textSnippetEntry.textDe }}</td>
                <td>{{ textSnippetEntry.textEn }}</td>
              </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>


  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"

export default {
  name: 'TextSnippetList',
  data() {
    return {
      deleteTextSnippetId: {}
    }
  },
  mounted: function() {
    this.doTextSnippetsFetchAll()
  },
  computed: {
    ...mapState({
      textSnippets: state => state.textSnippetsStore.entries,
      textSnippetsIsLoading: state => state.textSnippetsStore.isLoading,
    }),
  },
  methods: {
    ...mapActions({
      textSnippetsFetchAll: 'textSnippetsStore/fetchAll',
      textSnippetsDelete: 'textSnippetsStore/delete',
    }),
    doTextSnippetsFetchAll() {
      this.textSnippetsFetchAll({force: true})
    },
    deleteTextSnippet(textSnippet) {
      this.$confirm('Möchten Sie ' + textSnippet.name + ' wirklich aus löschen?').then((res) => {
        if (res) {
          this.$set(this.deleteTextSnippetId, textSnippet.id, true)
          this.textSnippetsDelete({
            id: textSnippet.id
          }).then(() => {
            this.$set(this.deleteTextSnippetId, textSnippet.id, false)
            this.doTextSnippetsFetchAll()
          })
        }
      })
    }
  },
}
</script>

<style scoped>
  .main-view {
      padding: 2rem;
      max-width: 2000px;
      margin: auto;
  }

  .snippet-text {
    width: 50%;
  }
</style>
