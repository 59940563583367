<template>
  <div class="main-view">

    <h1 class="headline" v-if="!form.id">Textsnippet hinzufügen</h1>
    <h1 class="headline" v-if="form.id">Textsnippet bearbeiten</h1>

    <div v-if="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <div v-if="!isLoading">
      <h2 v-if="form.ids">{{ form.title }}</h2>

      <v-form
        ref="userForm"
        v-model="form.valid"
        :lazy-validation="false"
      >

        <v-text-field v-model="form.name" label="Name (muss einmalig sein)" :rules="rules.required"></v-text-field>

        Inhalt (Deutsch):
        <v-textarea v-model="form.textDe" solo />
        <br>
        Inhalt (Englisch):
        <v-textarea v-model="form.textEn" solo />

        <div class="text-right mt-5">
          <v-btn color="secondary" class="mr-4" @click="cancel">Abbrechen</v-btn>
          <v-btn color="primary" :loading="isSaving" @click="saveTextSnippet" :disabled="!form.valid">Speichern</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: 'NewsAdminEdit',
  components: {
  },
  data() {
    return {
      isSaving: false,

      form: {
        isValid: false,

        id: null,
        name: '',
        textDe: '',
        textEn: '',
      },
      rules: {
        required: [
          v => !!v || 'Dieses Feld muss gefüllt sein',
        ],
      }
    }
  },
  mounted: function() {
    this.doTextSnippetsFetchAll()
  },
  computed: {
    ...mapState({
      textSnippets: state => state.textSnippetsStore.entries,
      textSnippetsIsLoading: state => state.textSnippetsStore.isLoading,
      textSnippetsIsDone: state => state.textSnippetsStore.isDone,
    }),
    ...mapGetters({
      getTextSnippetById: 'textSnippetsStore/getById'
    }),
    textSnippet() {
      if (this.$route.params.textSnippetId) {
        return this.getTextSnippetById(this.$route.params.textSnippetId)
      } else {
        return null
      }
    },
    isLoading() {
      // new never loads
      if (!this.$route.params.textSnippetId) {
        return false
      }

      return this.textSnippetsIsLoading
    }
  },
  methods: {
    ...mapActions({
      textSnippetsFetchAll: 'textSnippetsStore/fetchAll',
      textSnippetsCreate: 'textSnippetsStore/create',
      textSnippetsUpdate: 'textSnippetsStore/update',
      countriesFetchAll: "countriesStore/fetchAll"
    }),
    doTextSnippetsFetchAll() {
      this.textSnippetsFetchAll({force: true}).then(() => {
        this.updateForm()
      })
    },
    updateForm() {
      if (this.textSnippet) {
        this.form.id = this.textSnippet.id
        this.form.name = this.textSnippet.name
        this.form.text = this.textSnippet.text
        this.form.textDe = this.textSnippet.textDe
        this.form.textEn = this.textSnippet.textEn
      }
    },
    gotoList() {
      this.$router.push({name: "textsnippet-list"});
    },
    cancel() {
      this.gotoList()
    },
    saveTextSnippet() {
      this.isSaving = true

      let input = {
        name: this.form.name,
        text: this.form.textDe,
        textDe: this.form.textDe,
        textEn: this.form.textEn
      }
      let promise = null

      let isNew = false
      if (!this.form.id) {
        isNew = true
      }

      if (isNew) {
        promise = this.textSnippetsCreate({
          variables: {
            input: {
              ...input
            }
          }
        })
      } else {
        promise = this.textSnippetsUpdate({
          variables: {
            input: {
              id: this.form.id,
              ...input
            }
          }
        })
      }

      promise.then(() => {
        this.isSaving= false
        this.gotoList()
      }).catch(() => {
        this.isSaving= false
      })

    }
  }
}
</script>

<style scoped>
  .main-view {
      padding: 2rem;
      max-width: 1000px;
      margin: auto;
  }
</style>
